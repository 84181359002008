import React from "react"
import Layout from "../components/layout/Layout"
import styles from "../styles/text-section.module.scss"
import ContactForm from "../components/general/contactForm/kontaktuForma"
import { Helmet } from "react-helmet"
import BenefitsSection from "../components/landing/benefits"

export default function DUK() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Jūsų klausimai apie atliekamas automobilių remonto paslaugas.
        </title>
        <meta
          name="description"
          content="Turite klausimų apie automobilio remonto paslaugas? Čia rasite visus mūsų klientų jau užduotus klausimus ir atsakymai apie automobilio priežiūrą bei remonto darbus vienoje vietoje."
        ></meta>
      </Helmet>
      <section className={`${styles.textSection} container`}>
        <h1>Dažniausiai užduodami klausimai</h1>
        <h2>Kokius automobilius Jūsų servisas remontuoja?</h2>
        <p>
          Mūsų servise remontuojami Opel, BMW, Mercedes Benz, Skoda, Volkswagen,
          Audi, Honda ir kitų markių lengvieji automobiliai.
        </p>
        <h2>Koks Jūsų darbo laikas?</h2>
        <p>
          Autoservisas dirba darbo dienomis nuo 8:30 val. iki 18:00 val.,
          savaitgaliais ilsimės – nedirbame.
        </p>
        <h2>
          Ar reikia automobilio remontui Jūsų servise užsirašyti iš anksto?
        </h2>
        <p>
          Visada rekomenduojame užsirašyti iš anksto, tačiau jeigu atsitinka
          bėda – kreipkitės, stengsimės padėti. Jeigu planuojate didesnį
          automobilio remontą, užsirašyti rekomenduojame iš anksto. Tai galite
          padaryti telefonu 8 688 45247 arba el.paštu
          administracija@vaidoto183.lt.
        </p>
        <h2>Kiek kainuoja automobilio remontas?</h2>
        <p>
          Automobilio remonto kaina priklauso nuo numatomų darbų atlikimo
          trukmės ir gedimo. Kiekvieną gedimą aptariame su klientu ir darbus
          pradedame tik aptarę preliminarią sąmatą. Standartinių paslaugų
          įkainiai pateikti paslaugų skiltyje.
        </p>
        <h2>
          Ar atliekate automobilio paruošimą privalomajai techninei apžiūrai?
        </h2>
        <p>
          Automobilio paruošimą privalomajai techninei apžiūrai atliekame kai
          klientas gali pateikti techninės apžiūros išvadas, kuriose nurodyti
          automobilio trūkumai ir problemos, kurias reikia išspręsti norint
          praeiti techninę apžiūrą Regitroje.
        </p>
      </section>
      <ContactForm />
      <BenefitsSection />
    </Layout>
  )
}
